@import '../../../styles/customMediaQueries.css';

:global(.image-gallery-thumbnails-container) {
  text-align: left !important;
}
:global(.image-gallery-thumbnails) {
  padding-top: 24px;
  padding-bottom: 0;

  /*
   By default, the gallery controls the scroll position of the thumbnails when
   browsing the images. We change this logic to a freely scrollable container
   that the user controls. This overflow works together with the
   `disableThumbnailScroll` option in the component JS.
   */
  width: 100vw;
  overflow: auto;

  @media (--viewportMedium) {
    width: calc(100vw - 48px);
  }
  @media (--viewportLarge) {
    width: unset;
  }
}
:global(.image-gallery-thumbnail) {
  width: auto;
  border-radius: 8px;

  text-align: left !important;

  &:first-child {
    margin-left: 24px;

    @media (--viewportMedium) {
      margin-left: 0;
    }
  }
  &:last-child {
    margin-right: 24px;

    @media (--viewportMedium) {
      margin-right: 0;
    }
  }

  &:hover,
  &:global(.active) {
    border-color: var(--marketplaceColor);
  }
}

:global(.image-gallery-slide-wrapper) {
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowListingCard);

  @media (--viewportMedium) {
    border-radius: var(--borderRadiusMedium);
  }
}

:global(.fullscreen) {
  background-color: var(--colorWhite) !important;
  height: 100%;
  display: flex;
  flex-direction: column;

  & :global(.image-gallery-slide-wrapper) {
    background-color: transparent;
    border: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;

    & :global(.image-gallery-swipe) {
      margin-top: auto;
      margin-bottom: auto;
    }

    & :global(.image-gallery-slides) {
      margin-top: auto;
      margin-bottom: auto;
      padding: 0;

      @media (--viewportMedium) {
        padding: 0px 130px;
      }
    }

    & :global(.image-gallery-index) {
      left: 0;
      width: fit-content;
      margin: 24px;
      background: transparent;

      color: var(--colorGrey300);
      font-weight: var(--fontWeightBold);
    }
  }

  & :global(.image-gallery-thumbnails-container),
  & :global(.image-gallery-thumbnail) {
    text-align: center !important;
  }
}

.slideWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;

  padding: 10px;

  :first-child {
    grid-column: 1/3;
    grid-row: 1/-1;
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }

  &:hover {
    cursor: pointer;
  }
}

.itemWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 750px;
}

.imgWrapper {
  height: 100%;
  width: 100%;
}

.videoItem {
  height: 100%;
  visibility: hidden;
}

.itemFullscreen {
  @media (--viewportMedium) {
    max-height: 700px;
  }
}
.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);

  height: 750px;
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item,
.videoItem {
  transition: all 0.2s ease-in-out;
}

.thumb {
  /* With the active thumbnail border (default 4px), make the result
   height a multiple of the baseline. */
  max-width: 88px;
  max-height: 88px;
  border-radius: var(--borderRadiusMedium);
}

.navLeft,
.navRight {
  position: absolute;
  width: 60px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 0;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.6;

  background-color: rgba(255, 255, 255, 0.5);

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;
}

.openFullscreen {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Ensure the button is on top of the navRight area */
  z-index: 1;

  &:hover {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.close {
  composes: marketplaceModalCloseStyles from global;

  position: absolute;
  color: var(--colorGrey300);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--colorBlack);
  }
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 60%);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media (--viewportMedium) {
    justify-content: center;
    gap: 45px;
  }
}

.listingTitle {
  padding: 0;
  margin: 0;

  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  text-wrap: wrap;

  color: var(--colorAlmostWhite);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin: 200px 10px 50px 10px;
  padding: 0 30px;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin: 0;
    padding: 0;
  }
}

.videoBtn,
.galleryBtn {
  height: 50px;
  min-height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: var(--colorAlmostWhite);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  padding: 10px 40px;

  width: 100%;

  svg {
    flex-shrink: 0;
  }

  @media (--viewportMedium) {
    width: 200px;
  }
}

.hidden {
  visibility: hidden;
  height: 0;
}
