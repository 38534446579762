@import '../../../styles/customMediaQueries.css';

.modal {
  padding: 20px 36px !important;
  border-radius: 12px !important;

  @media (--viewportLarge) {
    margin-left: 0px;
    padding-left: 0px !important;
  }
}

.title {
  margin: 26px 10px 16px 16px;

  white-space: nowrap;

  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;

  color: var(--colorDarkBlue);

  @media (--viewportXSmall) {
    margin-top: 16px;
  }

  @media (--viewportMedium) {
    margin-left: 36px;
  }

  @media (--viewportLarge) {
    margin-bottom: 10px;
    text-align: start;
  }
}

.root {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (--viewportLarge) {
    flex-direction: row;
    gap: 36px;
  }
}

.aside {
  @media (--viewportLarge) {
    padding-right: 6px;
    border-right: 1px solid var(--colorGrey100);
  }
}

.addOnList {
  display: none;
  overflow: auto;
  max-height: calc(75vh - 184px);
  margin-right: 16px;

  @media (--viewportLarge) {
    display: block;
    margin-right: 0;
  }
}

.addOnSelect {
  @media (--viewportLarge) {
    display: none;
  }
}

.addOnBtn {
  min-height: fit-content;
  max-width: 252px;
  padding: 10px 18px;
  margin: 2px 0;

  border: none;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  text-align: start;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey600);

  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 4px;
  background-color: rgba(250, 250, 250, 1);
  height: 36px;
  width: 36px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.btnText {
  display: flex;
  flex-direction: column;
}
.btnTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 150px;

  color: var(--colorDarkBlue);
}

.btnCategory {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  color: rgba(160, 156, 167, 1);
}

.selected,
.addOnBtn:hover,
.addOnBtn:focus {
  background-color: var(--marketplaceColor);

  .btnTitle,
  .btnCategory {
    color: var(--colorWhite);
  }

  .btnCategory {
    opacity: 70%;
  }
}

.panel {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 22px;
  margin-top: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @media (--viewportXXSmall) {
    flex-direction: row;
  }
}

.text {
  margin: 0;
  padding: 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  color: var(--colorGrey);
}

.label {
  padding: 0;
  margin: 0;
  margin-right: 10px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-transform: capitalize;

  color: #3f3454;
}

.addOnTitle {
  margin: 0;
  padding: 0;

  text-align: center;

  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;

  color: var(--colorDarkBlue);
}

.video,
.description {
  @media (--viewportMedium) {
    width: 574px;
  }
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: var(--colorGrey);
}

.detailsWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.details {
  display: flex;
  flex-direction: column;

  @media (--viewportXSmall) {
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
}

.timeInfo {
  display: flex;
  align-items: center;
  gap: 6px;
}

.price {
  box-shadow: 0px 2px 20px -5px rgba(99, 48, 187, 0.13);
  background-color: var(--colorLightBlue);
  border-radius: 12px;
  padding: 10px 30px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--colorDarkBlue);
}

.priceAmount {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--colorTeal);
}

.btn {
  width: fit-content;
  padding: 0 20px;

  color: #f9f8fa;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  @media (--viewportXSmall) {
    align-self: center;
    padding: 0 110px;
  }
}
